import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import PaymentModal from "../components/PaymentModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Referrals() {
  const [showModal, setShowModal] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(new Date("1950/01/01"));
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (window.location.search.includes("success=true")) {
      setSuccess(true);
    }
  }, []);

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleOpenModal() {
    setShowModal(true);
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sunrise Speech and Swallowing Therapy | Who We Are</title>
        <link
          rel="canonical"
          href="https://sunrisespeechandswallowing.com/who-we-are"
        />
        <meta
          name="keywords"
          content="Speech therapy, Swallowing therapy, Asheville, NC"
        />
      </Helmet>
      <main>
        <Nav openModal={handleOpenModal} />
        {showModal && <PaymentModal closeModal={handleCloseModal} />}

        <section className="bg-white border-b py-8 mt-12 mx-auto max-w-6xl p-4">
          <h1 className="w-full my-2 mt-12 text-5xl font-bold leading-tight text-center text-sunrise-blue">
            Referrals
          </h1>
          {success && (
            <h2 style={{ color: "green", textAlign: "center" }}>
              Thank you for contacting us, we will respond as soon as possible!{" "}
            </h2>
          )}
          <div className="container max-w-l mx-auto m-8 text-center">
            <form
              name="referrals"
              method="POST"
              action="/referrals/?success=true"
              netlify-honeypot="bot-field"
              data-netlify="true"
              className="md:border-solid md:border-2 border-surise-blue md:shadow-lg p-8 pt-6 max-w-lg sm:mx-auto m-2"
            >
              <div className="max-w-lg mx-auto">
                <p className="hidden">
                  <label>
                    Don’t fill this out if you’re human:{" "}
                    <input name="bot-field" />
                  </label>
                </p>
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="referrals" />

                <div className="flex-1 lg:w-5/6 sm:w-full text-left">
                  <label htmlFor="name" className="pl-2">
                    Patient Name (required)
                  </label>
                  <input
                    type="text"
                    id="patient-name"
                    required
                    name="patient-name"
                    className="p-1 pl-2 text-sm mt-1 block w-full rounded-md border-sunrise-orange-1 shadow-sm focus:border-indigo-300 border mb-5"
                    placeholder=""
                  />
                </div>
                <div className="text-left w-full">
                  <input
                    type="hidden"
                    name="date-of-birth"
                    value={dateOfBirth}
                  />
                  <label htmlFor="name" className="pl-2">
                    Patient Date of Birth
                  </label>
                  <br />

                  <DatePicker
                    selected={dateOfBirth}
                    openToDate={new Date("1950/01/01")}
                    className="sm-w-full p-1 pl-2 text-sm mt-1 block w-full rounded-md border-sunrise-orange-1 shadow-sm focus:border-indigo-300 border mb-5"
                    onChange={(date) => setDateOfBirth(date)}
                  />
                </div>

                <div className="text-left">
                  <label htmlFor="street" className="pl-2">
                    Street
                  </label>
                  <input
                    type="text"
                    id="street"
                    name="street"
                    className="p-1 pl-2 text-sm mt-1 mb-2 block w-full rounded-md border-sunrise-orange-1 shadow-sm focus:border-indigo-300 border"
                    placeholder=""
                  />
                </div>
                <div className="flex mb-2">
                  <div className="w-4/6 text-left mr-4">
                    <label htmlFor="city" className="pl-2">
                      City
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      className="p-1 pl-2 text-sm mt-1 mb-2 block w-full rounded-md border-sunrise-orange-1 shadow-sm focus:border-indigo-300 border"
                      placeholder=""
                    />
                  </div>
                  <div className="text-left">
                    <label htmlFor="zipcode" className="pl-2">
                      Zipcode
                    </label>
                    <input
                      type="tel"
                      id="zipcode"
                      name="zipcode"
                      className="p-1 pl-2 text-sm mt-1 mb-2 block w-full rounded-md border-sunrise-orange-1 shadow-sm focus:border-indigo-300 border"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="text-left">
                  <label htmlFor="email" className="pl-2">
                    Email Address
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="p-1 pl-2 text-sm mt-1 mb-2 block w-full rounded-md border-sunrise-orange-1 shadow-sm focus:border-indigo-300 border"
                    placeholder=""
                  />
                </div>
                <div className="text-left">
                  <label htmlFor="phone" className="pl-2">
                    Phone (required)
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="p-1 pl-2 text-sm mt-1 block w-full rounded-md border-sunrise-orange-1 shadow-sm focus:border-indigo-300 border"
                    placeholder=""
                  />
                </div>
                <h2 className="mt-6 text-left border-b mb-3 border-gray-500">
                  Referring Physician
                </h2>
                <div className="text-left">
                  <label htmlFor="physician-name" className="pl-2">
                    Physician Name
                  </label>
                  <input
                    type="text"
                    id="physician-name"
                    name="physician-name"
                    required
                    className="p-1 pl-2 text-sm mt-1 block w-full rounded-md border-sunrise-orange-1 shadow-sm focus:border-indigo-300 border"
                    placeholder=""
                  />
                </div>
                <div className="text-left">
                  <label
                    htmlFor="patient-diagnoses"
                    className="w-full pl-2 mt-3 block"
                  >
                    Patient diagnoses:
                  </label>

                  <textarea
                    resize="none"
                    id="patient-diagnosis"
                    name="patient-diagnosis"
                    className="p-2 text-sm mt-1 block w-full border resize-none h-42 border-solid border-sunrise-orange-1 rounded-md"
                  ></textarea>
                </div>

                <div className="text-left">
                  <h6
                    htmlFor="Dysphagia"
                    className="underline w-full pl-2 mt-3 block"
                  >
                    Dysphagia
                  </h6>
                  <div>
                    <input
                      type="checkbox"
                      id="dysphagia-mild"
                      name="dysphagia-mild"
                      className="p-1 pl-4 ml-2 mr-2 text-sm mt- mt-2 rounded-md border-sunrise-orange-1 "
                      placeholder=""
                    />
                    <label>mild</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="dysphagia-moderate"
                      name="dysphagia-moderate"
                      className="p-1 pl-4 ml-2 mr-2 text-sm mt- mt-2 rounded-md border-sunrise-orange-1 "
                      placeholder=""
                    />
                    <label>moderate</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="dysphagia-severe"
                      name="dysphagia-severe"
                      className="p-1 pl-4 ml-2 mr-2 text-sm mt- mt-2 rounded-md border-sunrise-orange-1 "
                      placeholder=""
                    />
                    <label>severe</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="dysphagia-feeding-tube"
                      name="dysphagia-feeding-tube"
                      className="p-1 pl-4 ml-2 mr-2 text-sm mt- mt-2 rounded-md border-sunrise-orange-1 "
                      placeholder=""
                    />
                    <label>feeding tube</label>
                    <div className="mt-2">
                      <h6 className="pl-2">Please list current diet:</h6>
                      <textarea
                        resize="none"
                        id="current-diet"
                        name="current-diet"
                        className="p-2 text-sm mt-1 block w-full border resize-none h-10 border-solid border-sunrise-orange-1 rounded-md"
                      ></textarea>
                    </div>
                  </div>
                  <div className="text-left mt-2">
                    <h6
                      htmlFor="Dysphagia"
                      className="underline w-full pl-2 mt-3 block"
                    >
                      Communication Disorders:
                    </h6>
                    <div>
                      <input
                        type="checkbox"
                        id="expressive-aphasia"
                        name="expressive-aphasia"
                        className="p-1 pl-4 ml-2 mr-2 text-sm mt- mt-2 rounded-md border-sunrise-orange-1 "
                        placeholder=""
                      />
                      <label>Expressive Aphasia</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="receptive-aphasia"
                        name="receptive-aphasia"
                        className="p-1 pl-4 ml-2 mr-2 text-sm mt- mt-2 rounded-md border-sunrise-orange-1 "
                        placeholder=""
                      />
                      <label>Receptive Aphasia</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="global-aphasia"
                        name="global-aphasia"
                        className="p-1 pl-4 ml-2 mr-2 text-sm mt- mt-2 rounded-md border-sunrise-orange-1 "
                        placeholder=""
                      />
                      <label>Global Aphasia</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="dysarthria"
                        name="dysarthria"
                        className="p-1 pl-4 ml-2 mr-2 text-sm mt- mt-2 rounded-md border-sunrise-orange-1 "
                        placeholder=""
                      />
                      <label>Dysarthria</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="apraxia-of-speech"
                        name="apraxia-of-speech"
                        className="p-1 pl-4 ml-2 mr-2 text-sm mt- mt-2 rounded-md border-sunrise-orange-1 "
                        placeholder=""
                      />
                      <label>Apraxia of Speech</label>
                    </div>
                    <div className="mt-2">
                      <h6 className="pl-2">List specific voice disorders:</h6>
                      <textarea
                        resize="none"
                        id="voice-disorders"
                        name="voice-disorders"
                        className="p-2 text-sm mt-1 block w-full border resize-none h-10 border-solid border-sunrise-orange-1 rounded-md"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-6 flex justify-center">
                <button
                  className="bg-sunrise-orange-1 rounded-lg pt-2 pb-2 pr-6 pl-6 border-0 text-white shadow-md"
                  role="button"
                  type="submit"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </section>

        <Footer openModal={handleOpenModal} />
      </main>
    </>
  );
}

export default Referrals;
